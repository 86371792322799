import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import Container from '@/components/layouts/Container';
import { systemConfig } from './systemConfig';

export const NotFoundPage: FC = () => {
  const { locale } = useRouter();
  const { t } = useTranslation(systemConfig.i18nNamespaces);
  return (
    <Container title={t('system:notFound.title') as string}>
      <main className="flex flex-col w-full px-4 mx-auto mt-header-mobile max-w-8xl desktop:mt-header-desktop desktop:px-8 min-h-content grow">
        <div className="py-16 m-auto text-center desktop:py-32 shrink-0">
          {/* <div className="relative aspect-[19/10] h-auto w-full">
            <Image src="/static/images/not-found.webp" alt="" layout="fill" />
          </div> */}
          <h1
            className="mt-16 text-4xl font-bold tracking-tight desktop:text-5xl desktop:tracking-tight text-neutral-900 dark:text-neutral-100"
          >
            {t('system:notFound.title')}
          </h1>
          <p className="mt-2 text-base dark:text-neutral-50">
            {t('system:notFound.description')}
          </p>
          <div className="mt-6">
            <NextLink href="/" passHref locale={locale} legacyBehavior>
              <a className="font-semibold text-primary-50 hover:text-primary-40">
                <span aria-hidden="true">&larr; </span>
                {t('system:links.backToHome')}
              </a>
            </NextLink>
          </div>
        </div>
      </main>
    </Container>
  );
};
